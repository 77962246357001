export const h = {
    tab01:"首页",
    tab02:"车型展示",
    tab03:"关于我们",
    tab04:"人才招聘",
    tab05:"行业资讯",
    tab06:"业务咨询",
    tab07: '同业合作',
    wmdfu:'我们的服务',
    qfw:'全方位服务保障',
    fwys:'服务优势',
    rmcs:'热门城市',
    ffcx:'丰富车型 应有尽有',
    ckgdcx:'查看更多车型',
    hyfg:'行业覆盖',
    fwfw:"服务范围",
    dzyx:'电子邮箱',
    zyyw2:'主营业务',
    fwbz2:'服务保障',
    fwys2:'服务优势',
    rmcs2:'热门城市',
    cxzs2:'车型展示',
    hyfg2:'行业覆盖',
    fwfw2:'服务范围',

    zgdz:'中国地址',
    rbdz:'日本地址',
    adly:'澳大利亚',
    ygdz:'英国地址',
    xgdz:'香港地址',
    zgdz1:'浙江省杭州市滨江区长河街道众创路30号中赢星海创投中心2幢902室',

    rbdz1:'1-chōme-16-12 Mutsugi, Adachi City, Tokyo 121-0052日本',
    adly1:'MIRANDA NSW 2228悉尼',
    ygdz1:'5th Floor. Hyde Park Hayes , Hayes UB3 4AZ 英国',
    xgdz1:'Room D3, 11th Floor, Liuhe Industrial Building, No. 8 Liuhe Street, San Po Kong, Kowloon 香港',


    dj:'东京',
    ny:'纽约',
    xn:'悉尼',
    ld:'伦敦',
    rmcs01:'往返这座城市的 20 条路线',

    serveList01:'接送机',
    serveList0101:'全球范围,80+国家,500+城市,800+国际机场覆盖，5万+司机，提前24小时预约一站式服务,随时随地接送',
    serveList02:'点对点接送',
    serveList0201:'满足商务出行、酒店接送等个性化需求，便捷高效,轻松出行',
    serveList03:'司兼导包车',
    serveList0301:'陌生国度畅游，中文随行由5年以上专业司机驾车，任意选择车型随心自由探索，轻松畅享异国风情',
    fw01:"直接签约当地车队",
    fw0101:"坚持使用一手车队资源，增强管控力和价格优势",
    fw02:"专注服务于B端用户",
    fw0201:"深耕B2B用车领域，提供标准化价格方案，成为细分市场的领军企业",
    qfw01:"岗前培训，5年驾龄",
    qfw0101:"严格要求司机需有至少5年以上驾龄,为安全和服务保驾护航",
    qfw02:"行前沟通",
    qfw0201:"至少在用车前24小时与客人沟通确认行程，等待服务",
    qfw03:"中日英等20+种语言沟通",
    qfw0301:"贴近当地风土人情，严选本地生活多年的司机为主要服务者，满足各类客户需求",
    qfw04:"一价全含",
    qfw0401:"价格透明，无任何隐形消费，让客户体验更加优质放心",
    qfw05:"严格管理,优质保障 ",
    qfw0501:"建立完善的管理机制，对司导考核评分，确保每一次出行都有sop可追溯",
    hyfg01:"商旅出行",
    hyfg02:"礼宾车接送",
    hyfg03:"私人定制旅游包车（2-6人）",
    hyfg04:"留学生/移民/医疗/MCN等有出行需求的机构",
    // 关于我们支持服务
    zcfw01:"客户至上 服务为先",
    zcfw02:"全球四大运营中心，实现全天候、全时区的管控服务",
    zcfw03:"API接口快速对接，实现自动化订单",
    zcfw04:"全球覆盖，车型任选，让全球出行更简单",
    zcfw05:"同业操作系统",

    gsjs:'公司介绍',
    zcyfw:'支持与服务',
    hzhb:'合作伙伴',
    lxwm:'联系我们',
    gywm:"hrj-trip是一家全球用车服务平台，主营境外接送机、司兼导包车游，连接目的地司机为全球旅客出游提供整套的用车解决方案，提供安全、便捷、高品质的出行服务。在全球有4大运营中心，分布在中国杭州，日本东京，澳洲悉尼，英国伦敦，全时区管控服务。80+国家,500+城市,800+国际机场，5万+司机，可服务全球主流的旅游目的地",
    wsm:'为什么选择华人接',
    wsm01:'完全透明的收费',
    wsm02:'自由行程，自由定制',
    wsm03:'安全保障一流服务',
    wsm04:'全天候贴心服务',
    // 业务咨询
    
    cjwt:"常见问题",
    cjwt01:"1、如何联系我们？",
    cjwt0101:"我们的联系方式有We chact/What’s app/Line/Mail等，有用车需求用联系我们的客服人员，详见页面底部",
    cjwt02:"2、合作方式有哪些？",
    cjwt0201:"客服一对一VIP服务、API技术接口等",
    cjwt03:"3、付款方式有哪些？",
    cjwt0301:"支持银行电汇、PayPal、Payonner、支付宝、微信支付等",
    cjwt04:"4、可以开具用车凭证吗？",
    cjwt0401:"可免费提供invoice作为报销凭证，如需其他报销凭证类型，请直接联系客服人员，可能会需要加收税点费用",
    cjwt05:"5、可以提供哪些语言的司机？",
    cjwt0501:"我们可以提供中文、英语、日语、德语、法语、意语、泰语、西班牙语等20+语种的服务司机",
    cjwt06:"6、接送机免费等候时间是多久？",
    cjwt0601:"接机按照航班实际落地时间免费等候90分钟，送机按照预约时间免费等候30分钟",
    cjwt07:"7、用车费用都是包含哪些？",
    cjwt0701:"用车费、燃油费、高速费、停车费、路桥费等",
    

    // 轮播图
    banner1:"华人出行 当然“华人接”",
    banner11:"",
    banner2:"机场接机",
    banner22:"安全便捷出行无忧",
    banner3:"定制包车服务  专属自由行程",
    banner33:"尊享贵宾待遇",


    //同业合作
    thhz:'同行合作',
    zczh:'注册账号',
    xm:'姓名',
    yx:'邮箱',
    sj:'手机',
    mm:'密码',
    qrmm:'确认密码',
    gsxx:'公司信息',

    gsmc:'公司名称',
    gsdz:'公司地址',
    gswz:'公司网址',
    khh:'开户行',
    yyzz:'营业执照',
    yyzz1:'只能上传jpg/Png文件、且不超过500Kb',
    tjsh:'提交审核',
    tjsh1:'我们会在提交审核后24小时联系您',

    djsc:'点击上传',

    xm1:'请输入姓名',
    yx1:'请输入邮箱',
    sj1:'请输入手机号码',
    mm1:'请输入密码',
    qrmm1:'请输入确定密码',
    gsxx1:'公司信息',

    gsmc1:'请输入公司名称',
    gsdz1:'请输入公司地址',
    gswz1:'请输入公司网址',
    khh1:'请输入开户行',

    //业务咨询
    ywzx001:'请留下您的联系方式，我们将尽快与您联系',
    lxfs:"联系方式",
    ndxq:'你的需求',
    tj:'提 交',
    xl:'行李',
    


}