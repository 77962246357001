export const h = {
    tab01:"Home",
    tab02:"Our Fleet",
    tab03:"About Us",
    tab04:"Talent Recruitment",
    tab05:"Industry News",
    tab06:"Business Consulting",
    tab07: 'B2B Business Partnership',
    wmdfu:'Our Services',
    qfw:'Comprehensive Service Guarantee',
    fwys:'Service Advantages',
    rmcs:'Popular Cities',
    ffcx:'A rich variety of car models, all available',
    ckgdcx:'View more car models',
    hyfg:'Industry Coverage',
    fwfw:"Service Area",
    dzyx:'E-mail',

    zyyw2:'Main Business',
    fwbz2:'Service Guarantee',
    fwys2:'Service Advantages',
    rmcs2:'Popular Cities',
    cxzs2:'Our Fleet',
    hyfg2:'Industry Coverage',
    fwfw2:'Service Area',

    zgdz:'China',
    rbdz:'Japan',
    adly:'Australia',
    ygdz:'United Kingdom',
    xgdz:'Hong Kong',
    zgdz1:'Room 902, Building B, Zhongying Xinghai, Binjiang District, Hangzhou City, Zhejiang Province',
    rbdz1:'1-chōme-16-12 Mutsugi, Adachi City, Tokyo 121-0052 Japan',
    adly1:'MIRANDA NSW 2228 Sydney',
    ygdz1:'5th Floor. Hyde Park Hayes , Hayes UB3 4AZ UK',
    xgdz1:'Room D3, 11th Floor, Liuhe Industrial Building, No. 8 Liuhe Street, San Po Kong, Kowloon Hong Kong',

    dj:'Tokyo',
    ny:'New York',
    xn:'Sydney',
    ld:'London',
    rmcs01:'20 routes to and from this city',

    serveList01:'Airport Pickup and Drop-off',
    serveList0101:'Worldwide, 80+ countries, 500+ cities, 800+ international airport coverage, 50,000+ drivers, one-stop service 24 hours in advance, anytime and anywhere',
    serveList02:'Point-to-Point Transfer',
    serveList0201:'Meet the personalized needs of business travel, hotel Airport Pickup and Drop-off, convenient and efficient, easy travel',
    serveList03:'Driver-guide chartered car',
    serveList0301:'Travel in a strange country, accompanied by your mother tongue Driven by a professional driver with more than 5 years of experience, you can choose any vehicle type Feel free to explore and enjoy the exotic with ease',
    fw01:"Direct contract with local teams",
    fw0101:"Adhere to the use of first-hand fleet resources to enhance control and price advantages",
    fw02:"Specialize in B2B services",
    fw0201:"Deeply cultivate the field of B2B vehicles, provide standardized price solutions, and become a leader in the market segment",
    qfw01:"Pre-job training, access mechanism",
    qfw0101:"Drivers are strictly required to have at least 5 years of driving experience to escort safety and service",
    qfw02:"Pre-departure communication",
    qfw0201:"Communicate with guests at least 24 hours before the use of the car to confirm the itinerary and wait for the service",
    qfw03:"Support 20+ languages: Chinese, Japanese, English",
    qfw0301:"Adapt to local customs, use experienced, long-term local drivers as primary service providers",
    qfw04:"All inclusive",
    qfw0401:"The price is transparent, and there is no hidden consumption, so that the customer experience is more high-quality and assured",
    qfw05:"Strict management, high-quality guarantee ",
    qfw0501:"Establish a sound management mechanism, evaluate and score the driver, and ensure that every trip is SOP traceable",
    hyfg01:"Business travel",
    hyfg02:"Limousine transfers",
    hyfg03:"Private Customized Tour (2-6 People)",
    hyfg04:"travel for students, immigration, medical, MCN, etc",
    // 关于我们支持服务
    zcfw01:"Customer first, service first",
    zcfw02:"Four global operation centers provide around-the-clock management",
    zcfw03:"API interface is quickly docked to realize automated orders",
    zcfw04:"Global coverage, choose from a variety of models, making it easier to travel around the world",
    zcfw05:"Peer operating systems",
    gsjs:'Company Profile',
    zcyfw:'Support and Services',
    hzhb:'Cooperative Partner',
    lxwm:'Contact Us',
    gywm:"HRJ-TRIP is a global car service platform specializing in airport pick-ups, driver and guide chartered tours, and comprehensive car solutions for international travelers. Our services ensure safe, convenient, and high-quality travel experiences. We operate through four major centers worldwide, located in Hangzhou, China; Tokyo, Japan; Sydney, Australia; and London, United Kingdom, offering support across all time zones. HRJ-TRIP operates in over 80 countries, 500 cities, and 800 international airports, with a network of over 50,000 drivers, catering to the world’s key tourist destinations.",
    wsm:'',
    wsm01:'Full transparency of charges',
    wsm02:'Free itinerary and free customization',
    wsm03:'First-class security and service',
    wsm04:'Round-the-clock attentive service',
    // 业务咨询
    
    cjwt:"Q&A",
    cjwt01:"1. How can I contact you?",
    cjwt0101:"You can reach us via Wechat, WhatsApp, Line, email, and more. For specific contact details, please refer to the bottom of the page or get in touch with our customer service team for any car-related inquiries.",
    cjwt02:"2. What are the available cooperation options?",
    cjwt0201:"We offer customer service support, one-on-one VIP service, API technical integration, and more.",
    cjwt03:"3. What are the accepted payment methods?",
    cjwt0301:"We accept bank wire transfers, PayPal, Payoneer, Alipay, Wechat Pay, and more.",
    cjwt04:"4. Can I receive a voucher for car services?",
    cjwt0401:"We can provide an invoice free of charge as a reimbursement voucher. If you require other types of reimbursement documents, please contact our customer service team directly, as additional tax charges may apply.",
    cjwt05:"5.What languages do the drivers speak?",
    cjwt0501:"Our drivers can provide services in over 20 languages, including Chinese, English, Japanese, German, French, Italian, Thai, and Spanish.",
    cjwt06:'6. How long is the waiting time for airport pick-up?',
    cjwt0601:'We offer 90 minutes of free waiting time for pick-ups after flight arrival and 30 minutes of free waiting time for drop-offs after reservation.',
    cjwt07:'7. What are the car-related expenses?',
    cjwt0701:'Car expenses include fares, fuel fees, highway tolls, parking fees, and road and bridge tolls.',

    // 轮播图
    banner1:"Travel Like a Local",
    banner11:"",
    banner2:"Airport transfers",
    banner22:"safe and convenient, stress-free travel",
    banner3:"Customized chauffeur service, exclusive flexible itineraries",
    banner33:"VIP treatment",


     //同业合作
     thhz:'B2B Business Partnership',
     zczh:'Register an Account',
     xm:'name',
     yx:'mailbox',
     sj:'phone',
     mm:'password',
     qrmm:'confirm password',
     gsxx:'Company Information',
 
     gsmc:'corporate name',
     gsdz:'company address',
     gswz:'company website',
     khh:'bank',
     yyzz:'business license',
     yyzz1:'Only JPG/Png files can be uploaded, and they should not exceed 500Kb',
     tjsh:'Submit for review',
     tjsh1:'We will contact you 24 hours after submitting the review',

     djsc:'Click to upload',

    xm1:'Please enter your name',
    yx1:'Please enter your email address',
    sj1:'Please enter your phone number',
    mm1:'Please input a password',
    qrmm1:'Please enter the confirmed password',

    gsmc1:'Please enter the company name',
    gsdz1:'Please enter the company address',
    gswz1:'Please enter the company website',
    khh1:'Please enter the bank of account opening',

     //业务咨询
     ywzx001:'Please leave your contact information, we will contact you as soon as possible',
     lxfs:"contact information",
     ndxq:'Your needs',
     tj:'submit',
     xl:'luggage',
}