import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'layout',
    redirect: '/home',
    component: () => import(/* webpackChunkName: "about" */ '../views/layout/index.vue'),
    children: [
      {
        path: 'home',
        name: 'home',
        component: () => import(/* webpackChunkName: "about" */ '../views/home/index.vue'),
      },
      {
        path: 'carModel',
        name: 'carModel',
        component: () => import(/* webpackChunkName: "about" */ '../views/carModel/index.vue'),
      },
      {
        path: 'aboutUs',
        name: 'aboutUs',
        component: () => import(/* webpackChunkName: "about" */ '../views/aboutUs/index.vue'),
      },
      {
        path: 'news',
        name: 'news',
        component: () => import(/* webpackChunkName: "about" */ '../views/news/index.vue'),
      },//
      {
        path: 'cooperate',
        name: 'cooperate',
        component: () => import(/* webpackChunkName: "about" */ '../views/cooperate/index.vue'),
      },
      {
        path: 'job',
        name: 'job',
        component: () => import(/* webpackChunkName: "about" */ '../views/job/index.vue'),
      },//
      {
        path: 'industryNews',
        name: 'industryNews',
        component: () => import(/* webpackChunkName: "about" */ '../views/industryNews/index.vue'),
      },//businessConsult
      {
        path: 'businessConsult',
        name: 'businessConsult',
        component: () => import(/* webpackChunkName: "about" */ '../views/businessConsult/index.vue'),
      },
      {
        path: 'industryCoverage',
        name: 'industryCoverage',
        component: () => import(/* webpackChunkName: "about" */ '../views/industryCoverage/index.vue'),
      },
    ]
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 } // 将滚动位置设置为顶部
  }
})

router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0); // 将滚动位置设置为顶部
  next();
});

export default router
