<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>

export default {
  mounted() {
    this.$router.afterEach((to, from, next) => {
      window.scrollTo(0, 0)
    })
    // EventBus.$on('scllowTop', () => {
    //   this.scllowDb()
    // })
  },
  methods: {
    scllowDb() {
      console.log("1111111home", document.querySelector('#app'))
      document.querySelector('#app').scrollIntoView({
        behavior: "smooth"
      });

    }
  }
}
</script>
<style lang="scss">
@import './assets/styles/custom.scss';

div {
  box-sizing: border-box;
}
</style>
